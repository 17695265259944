<template>
  <div class="question-page">
    <div class="step" v-if="step === 1">
      <div class="inner">
        <div class="title">測驗注意事項</div>
        <!-- <el-scrollbar wrapClass="desc-container" style="margin-bottom: 40px">

        </el-scrollbar> -->
        <div class="desc" v-html="questionData.Announcement"></div>

        <div class="button-wrap">
          <span class="button" @click.prevent="step = 2"> 下一步 </span>
        </div>
      </div>
    </div>
    <div class="step" v-if="step === 2">
      <div class="inner">
        <div class="title">受測人基本資訊</div>
        <table>
          <tr>
            <th>姓名</th>
            <td colspan="3">{{ questionData.UserInformation.EmpCName }}</td>
          </tr>
          <tr>
            <th>部門</th>
            <td colspan="3">
              {{ questionData.UserInformation.DepartmentName }}
            </td>
          </tr>
          <template v-if="!isInterviewer">
            <tr>
            <th>職稱</th>
            <td colspan="3">{{ questionData.UserInformation.TitleName }}</td>
          </tr>
          <tr>
            <th>到職日</th>
            <td>{{ questionData.UserInformation.GroupDate }}</td>
            <th>年資</th>
            <td>{{ questionData.UserInformation.WorkAge }}</td>
          </tr>
          <tr>
            <th>生日</th>
            <td>{{ questionData.UserInformation.BirthDate }}</td>
            <th>年齡</th>
            <td>{{ questionData.UserInformation.Age }}</td>
          </tr>
          <tr>
            <th>學歷</th>
            <td>{{ questionData.UserInformation.SchoolName }}</td>
            <th>科系</th>
            <td>{{ questionData.UserInformation.MajorName }}</td>
          </tr>
          </template>


          <!-- <tr>
            <th>外派次數</th>
            <td colspan="3">
              <el-input
                style="width: 100%"
                v-model="ExpatriateTime"
                @input="numberChange"
                @change="numberChange"
                :min="0"
                type="number"
              />
            </td>
          </tr> -->
          <!-- <tr>
            <th>外派地點</th>
            <td colspan="3">
              <el-select v-model="ExpatriatePlaceGuid" placeholder="" style="width: 100%">
                <el-option
                  v-for="item in locationOptions"
                  :key="item.Value"
                  :label="item.Text"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </td>
          </tr> -->
        </table>
        <div class="desc" v-html="questionData.Remark"></div>
        <!-- <el-scrollbar wrapClass="ready-container" style="margin-bottom: 40px">

        </el-scrollbar> -->

        <div class="button-wrap">
          <span class="button" @click.prevent="startTest()"> 開始作答 </span>
        </div>
      </div>
    </div>
    <div class="step" v-if="step === 3">
      <div class="space-block">
        <div
          class="progress-line-bar"
          :style="
            'width:calc(200px + (100vw - 400px) * ' +
            currentQuestion / questionData.Questions.length +
            ')'
          "
        >
          <div>
            已作答 <span>{{ currentQuestion }}</span> /{{ questionData.Questions.length }}
            題
          </div>
          <p></p>
        </div>
        <div class="space-wrap">
          <img class="boat" src="@/assets/Images/spacemanboat.png" alt="" />
          <img class="line" src="@/assets/Images/spaceline.png" alt="" />
          <img class="man" src="@/assets/Images/spaceman.png" alt="" />
        </div>
      </div>
      <div
        class="question-wrap"
        v-for="(question, index) in questionData.Questions"
        :key="question.Guid"
      >
        <template v-if="currentQuestion === index">
          <div class="question">{{ question.SeqNo }}.{{ question.Topic }}</div>

          <label class="option" v-for="option in question.Options" :key="option.Guid">
            <input
              type="radio"
              :name="index"
              :value="option.Guid"
              @click="nextQuestion(index, option.Guid)"
              v-model="question.EvaluationOptionGuid"
            />
            <span>{{ option.Content }}</span>
          </label>

          <div class="button-wrap">
            <button
              class="button back"
              @click.prevent="returnQuestion(index)"
              v-if="index !== 0 && !question.useBack"
            >
              <i class="el-icon-arrow-left"></i>回上一題
            </button>
            <!-- <button
              class="button next"
              :disabled="!question.EvaluationOptionGuid"
              v-if="index !== questionData.Questions.length - 1"
              @click.prevent="nextQuestion(index)"
            >
              下一題 <i class="el-icon-arrow-right"></i>
            </button> -->
            <button
              class="button complete"
              :disabled="!question.EvaluationOptionGuid"
              v-if="index === questionData.Questions.length - 1"
              @click.prevent="sendQuestion(index)"
            >
              確認送出 <i class="el-icon-arrow-right"></i>
            </button>
          </div>
        </template>
      </div>
    </div>

    <el-dialog
      title="作答逾時"
      :visible.sync="limitDialogVisible"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-html="questionData.AnswerLimitMemo"></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="reloadPage()">重新作答</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screen: null, // loading遮罩
      questionData: {},
      step: 1,
      currentQuestion: 0,
      ExpatriateTime: null, //外派次數
      ExpatriatePlaceGuid: null, //外派地點
      locationOptions: [], //外派選項
      limitDialogVisible: false, //逾時提醒
      isInterviewer:false //是否為面試人員
    };
  },
  methods: {
    // numberChange(val) {
    //   let num = val;

    //   this.$nextTick(() => {
    //     if (num > 0) {
    //       this.ExpatriateTime = num;
    //     } else {
    //       this.ExpatriateTime = null;
    //     }
    //   });
    // },
    getData() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Answer`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });

            this.$router.push({ path: "home" });
          } else {
            this.questionData = response.data.Data;

            this.questionData.Questions = this.questionData.Questions.map((item) => ({
              ...item,
              useBack: false,
              startTime: null,
              endTime: null,
              EvaluationOptionGuid: null,
            }));
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //取得外派地點
    getLocation() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Answer/Options/ExpatriatePlace`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.locationOptions = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 開始作答
    startTest() {
      this.step = 3;
      this.questionData.Questions[0].startTime = new Date().getTime();
      if (this.questionData.AnswerLimit) {
        setTimeout(() => {
          this.limitDialogVisible = true;
        }, this.questionData.AnswerLimit * 1000);
      }
    },

    // 下一題
    nextQuestion(index, guid) {
      this.questionData.Questions[index].useBack = true;

      this.questionData.Questions[index].EvaluationOptionGuid = guid;
      if (index < this.questionData.Questions.length - 1) {
        this.questionData.Questions[index].endTime = new Date().getTime();
        this.questionData.Questions[index + 1].startTime = new Date().getTime();
        this.currentQuestion = index + 1;
      }
    },

    // 上一題
    returnQuestion(index) {
      this.questionData.Questions[index].useBack = true;
      this.questionData.Questions[index - 1].startTime = new Date().getTime();
      this.questionData.Questions[index - 1].endTime = null;
      this.currentQuestion = index - 1;
    },

    //重新作答
    reloadPage() {
      this.$router.go(0);
    },

    // 送出
    sendQuestion(index) {
      const vm = this;
      vm.screen = this.openLoading();

      vm.questionData.Questions[index].endTime = new Date().getTime();

      const timeStart = vm.questionData.Questions[0].startTime;
      const timeEnd =
        vm.questionData.Questions[vm.questionData.Questions.length - 1].endTime;

      const allTime = Math.floor((timeEnd - timeStart) / 1000);

      const api = `${window.BaseUrl.api}/Eva/Answer/${vm.questionData.EvaluationPersonGuid}`;
      const AnswersData = vm.questionData.Questions.map((item) => ({
        EvaluationQuestionGuid: item.Guid,
        EvaluationOptionGuid: item.EvaluationOptionGuid,
        AnswerTime: Math.floor((item.endTime - item.startTime) / 1000),
      }));
      const data = {
        CostSeconds: allTime,
        // ExpatriateTime: this.ExpatriateTime,
        ExpatriatePlaceGuid: this.ExpatriatePlaceGuid,
        Answers: AnswersData,
      };

      vm.$http.post(api, data).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: "錯誤",
            message: response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        } else {
          // this.$store.dispatch("alertMessageModule/updateMessage", {
          //   message: "送出成功",
          //   status: "success",
          // });

          localStorage.setItem("TestResult", JSON.stringify(response.data.Data));

          this.$router.push({
            name: "Complete",
          });
        }

        this.screen.close();
      });
    },
  },
  created() {
    this.startTime = new Date().getTime();
    this.getLocation();
    this.getData();


    let roles = localStorage.getItem('RoleName');
    let targetRole = "面試人員";

      // 創建正規表達式來精確匹配目標角色
    let regex = new RegExp(`(^|,)${targetRole}($|,)`);

      if (regex.test(roles)) {
        this.isInterviewer = true;
      } else {
        this.isInterviewer = false;
      }

  },
};
</script>

<style lang="scss">
.question-page {
  height: 100vh;
  background-image: url("~@/assets/Images/37130.jpg");
  background-size: cover;
  position: relative;


  &:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 41, 126, 0.6);
    z-index: 1;
  }

  .step {
    position: relative;
    z-index: 2;
    color: #fff;
    height: 100vh;
    padding-bottom: 30px;
    overflow-y: auto;
    .inner {
      max-width: 550px;
      margin: 0 auto;
      padding-top: 60px;
      padding-left: 10px;
      padding-right: 10px;
      .title {
        border: 1px solid #fff;
        padding: 10px 60px;
        margin-bottom: 20px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 40px;
        th {
          background-color: rgba(#fff, 0.1);
          text-align: center;
        }
        th,
        td {
          padding: 10px;
          border: 1px solid rgba(#fff, 0.5);
        }
      }
    }

    .button-wrap {
      text-align: center;
      .button {
        background-color: #d88316;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        border: none;
        cursor: pointer;
        &.back {
          float: left;
        }
        &.next,
        &.complete {
          float: right;
        }
        &[disabled="disabled"] {
          opacity: 0.5;
          cursor: no-drop !important;
        }
      }
    }
  }

  .space-block {
    text-align: left;
    display: flex;
    margin-top: -35px;
    .progress-line-bar {
      padding-top: 129px;
      position: relative;
      z-index: 9;
      transition: width 0.3s;

      div {
        color: #fff;
        float: right;
        position: relative;
        top: -35px;
        right: 10px;
        span {
          color: #ffd13b;
          font-size: 1.5em;
          font-weight: bold;
        }
      }
      p {
        display: block;
        background-color: #fff;
        height: 4px;
        margin: 0;
      }
    }

    .space-wrap {
      position: relative;
      img {
        width: 200px;
      }
      .line {
        position: relative;
        z-index: 2;
        bottom: -5px;
        left: -5px;
      }
      .man {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        animation: waving 2.8s linear infinite;
      }
      .boat {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        animation: rotating 20s linear infinite;
      }
    }
  }

  .question-wrap {
    margin: 0 auto;
    width: 1100px;
    max-width: 90%;
    .question {
      // color: #35dada;
      color: #ffd13b;
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .option {
      display: block;
      span {
        background-color: rgba(#fff, 0.9);
        color: #171f36;
        width: 100%;
        display: block;
        margin-bottom: 15px;
        padding: 10px 20px;
        border-radius: 25px;
        cursor: pointer;
      }
      input {
        display: none;

        &:checked {
          & + span {
            // background-color: #0eaaaa;
            background-color: #d88316;
            color: #fff;
          }
        }
      }

      &:hover {
        span {
          background-color: #fcdbb0;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes waving {
  0% {
    top: -5px;
  }
  50% {
    top: 5px;
  }
  100% {
    top: -5px;
  }
}

</style>
